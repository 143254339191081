<template>
  <div style="overflow: hidden;">
    <div>
      <NavBar
      style="max-height: 50px;margin-bottom: 10px;"
      :username="currentUserName"
      :pageName="pageName"
      ></NavBar>
    </div>
    <v-app>
      <!-- Create Walk-in popup -->
      <v-dialog v-model="createPopup" max-width="60%" persistent style="overflow: hidden;">
        <v-card>
          <div class="TitleHeader">
            <div>
              <v-card-title class="TitleText">
                Select Walk-in Doctors
              </v-card-title>
            </div>
          </div>
          <div align="left" style="overflow: hidden;">
            <v-row>
              <v-col cols="6" align="center">
                <div style="display: flex; margin-top: 10px;">
                  <div style="padding: 15px;">
                    Specialist:
                  </div>
                  <div>
                    <v-select
                      v-model="selected_specialization_list"
                      :items="specialization_list"
                      item-text="specialization_name"
                      item-value="_id"
                      @change="queryDoctors"
                      return-object
                      placeholder="Select Specializations"
                      outlined
                      >
                    </v-select>
                  </div>
                </div>
              </v-col>
            </v-row>
            <div align="center" style="margin-bottom: 40px;" v-if="popupIsLoading">
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </div>
            <v-row class="AllAvailableClass" v-if="selected_doctor_list.length > 0">
              <p class="AllAvailableTextClass">Selected doctors ( {{selected_doctor_list.length}} )</p>
            </v-row>
            <v-row style="padding-left: 36px; padding-top: 20px;" v-if="selected_doctor_list.length > 0">
              <v-slide-group>
                <v-slide-item align="center" v-for="selected_doctor in selected_doctor_list" :key="selected_doctor._id" style="margin: 0 8px 24px 0 !important;">
                  <v-card
                    active-class="active"
                    class="activeClass"
                    elevation="2"
                    height="160"
                    width="158"
                    justify="center"
                    style="margin-right:8px;"
                    >
                    <div align="right">
                      <v-icon @click="RemoveParticular(selected_doctor)" color="error" style="padding:3px 8px;">mdi-close-circle-outline</v-icon>
                    </div>
                    <v-avatar style="padding-top: 10%" size="70">
                      <img
                        v-bind:src="selected_doctor.doctor_profile_picture"
                        alt="Image"
                      />
                    </v-avatar>
                    <div>
                      <h3 style="font-size: 0.7em">
                        {{ selected_doctor.doctor_name }}
                      </h3>
                    </div>
                    <div style="justify-content: center; padding-top: 3%">
                      <p
                        style="
                          font-weight: 300;
                          padding: 0px 5px;
                          font-size: 0.8em;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                      {{selected_doctor.doctor_id}}
                      </p>
                    </div>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-row>
            <v-row  class="AllAvailableClass" v-if="all_doctor_list.length > 0">
              <p class="AllAvailableTextClass">Available doctors ( {{all_doctor_list.length}} )</p>
            </v-row>
            <v-row style="padding-left: 36px; padding-top: 30px; height: 30vh; overflow-y: scroll;">
              <div style="display: flex; flex-flow: wrap; flex-direction:row;">
                <div align="center" v-for="doctor in all_doctor_list" style="margin: 0 8px 24px 0; ">
                  <v-card
                    elevation="2"
                    height="160"
                    width="158"
                    justify="center"
                    @click="AddParticularDoctor(doctor)"
                    style="margin-right:8px;"
                    >
                    <v-avatar style="padding-top: 10%" size="70">
                      <img
                        v-bind:src="doctor.doctor_profile_picture"
                        alt="Image"
                      />
                    </v-avatar>
                    <div>
                      <h3 style="padding-top: 6%; font-size: 0.9em">
                        {{ doctor.doctor_name }}
                      </h3>
                    </div>
                    <div style="justify-content: center; padding-top: 3%">
                      <p
                        style="
                          font-weight: 300;
                          padding: 0px 5px;
                          font-size: 0.8em;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                      {{doctor.doctor_id}}
                      </p>
                    </div>
                  </v-card>
                </div>
              </div>
            </v-row>
            <v-row>
              <v-row>
              <div style="width: 100%" align="right">
                <v-btn style="padding: 20px; margin: 10px 30px 30px 10px; background-color: #1467BF;" class="primary" @click="ModifyWalkin()" :disabled = "(selected_doctor_list.length == 0) || isDisabled">
                  <span>Update</span>
                  <span v-if="isDisabled">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </span>
                </v-btn>
                <v-btn style="padding: 20px; margin: 10px 30px 30px 10px; background-color: #1467BF;" class="primary" @click="CloseCreateWalkIn()" :disabled = "isDisabled">
                  <span>Cancel</span>
                </v-btn>
              </div>
            </v-row>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <!-- End create Walk-in popup -->

      <!-- View popup -->
      <!-- <v-dialog v-model="viewPopup" max-width="60%" style="overflow: hidden;">
        <v-card>
          <v-row v-if="isViewLoading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
          <div v-if="!isViewLoading" class="ViewTitle">
            <div>
              <v-card-title class="TitleText">
                View
              </v-card-title>
            </div>
            <div align="right" style="display: flex;">
              <div class="TitleText">
                {{ readDate }}
              </div>
            </div>
          </div>
          <v-row class="AllAvailableClass" v-if="allWalkinsList.length > 0">
              <p class="AllAvailableTextClass">Selected Doctors1 ( {{allWalkinsList.length}} )</p>
          </v-row>
          <v-row class="AllAvailableClass" v-if="!isViewLoading">
            <p class="AllAvailableTextClass">Selected Doctors1 ({{ selected_doctor_list.length }})</p>
          </v-row>
          <v-row v-if="!isViewLoading" style="padding-left: 36px; padding-top: 20px;">
            <div style="display: flex; flex-flow: wrap; flex-direction:row;">
              <div align="center" v-for="doctor in selected_doctor_list" style="margin: 0 8px 24px 0; ">
                <v-card
                  elevation="2"
                  height="160"
                  width="158"
                  justify="center"
                  style="margin-right:8px;"
                  >
                  <v-avatar style="padding-top: 10%" size="70">
                    <img
                      v-bind:src="doctor.doctor_profile_picture"
                      alt="Image"
                    />
                  </v-avatar>
                  <div>
                    <h3 style="padding-top: 6%; font-size: 0.9em">
                      {{ doctor.doctor_name }}
                    </h3>
                  </div>
                  <div style="justify-content: center; padding-top: 3%">
                    <p
                      style="
                        font-weight: 300;
                        padding: 0px 5px;
                        font-size: 0.8em;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                    >
                    {{doctor.doctor_id}}
                    </p>
                  </div>
                </v-card>
              </div>
            </div>
          </v-row>
          <v-row>
            <div style="width: 100%" align="right">
              <v-btn style="padding: 20px; margin: 10px 30px 30px 10px;" class="primary" @click="CloseViewWalkin()" :disabled = "isDisabled">
                <span>Close</span>
              </v-btn>
            </div>
          </v-row>
        </v-card>
      </v-dialog> -->
      <!-- End View Popup -->

      <!-- Edit popup -->
      <!-- <v-dialog v-model="editPopup" max-width="60%" persistent style="overflow: hidden;">
        <v-card>
          <div class="TitleHeader">
            <div>
              <v-card-title class="TitleText">
                Select Walk-in Doctors
              </v-card-title>
            </div>
          </div>
          <div align="left" style="overflow: hidden;">
            <v-row>
              <v-col cols="6" align="center">
                <div style="display: flex; margin-top: 10px;">
                  <div style="padding: 15px;">
                    Specialist:
                  </div>
                  <div>
                    <v-select
                      v-model="selected_specialization_list"
                      :items="specialization_list"
                      item-text="specialization_name"
                      item-value="_id"
                      @change="queryEditDoctors"
                      return-object
                      placeholder="Select Specializations"
                      outlined
                      >
                    </v-select>
                  </div>
                </div>
              </v-col>
            </v-row>
            <div align="center" style="margin-bottom: 40px;" v-if="popupIsLoading">
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </div>
            <v-row class="AllAvailableClass" v-if="allWalkinsList.length > 0">
              <p class="AllAvailableTextClass">Selected Doctors1 ( {{allWalkinsList.length}} )</p>
            </v-row>
            <v-row class="AllAvailableClass" v-if="selected_doctor_list.length > 0">
              <p class="AllAvailableTextClass">Selected doctors ( {{selected_doctor_list.length}} )</p>
            </v-row>
            <v-row style="padding-left: 36px; padding-top: 20px;" v-if="selected_doctor_list.length > 0">
              <v-slide-group>
                <v-slide-item align="center" v-for="selected_doctor in selected_doctor_list" :key="selected_doctor._id" style="margin: 0 8px 24px 0 !important;">
                  <v-card
                    active-class="active"
                    class="activeClass"
                    elevation="2"
                    height="160"
                    width="158"
                    justify="center"
                    style="margin-right:8px;"
                    >
                    <div align="right">
                      <v-icon @click="RemoveParticular(selected_doctor)" color="error" style="padding:3px 8px;">mdi-close-circle-outline</v-icon>
                    </div>
                    <v-avatar style="padding-top: 10%" size="70">
                      <img
                        v-bind:src="selected_doctor.doctor_profile_picture"
                        alt="Image"
                      />
                    </v-avatar>
                    <div>
                      <h3 style="font-size: 0.7em">
                        {{ selected_doctor.doctor_name }}
                      </h3>
                    </div>
                    <div style="justify-content: center; padding-top: 3%">
                      <p
                        style="
                          font-weight: 300;
                          padding: 0px 5px;
                          font-size: 0.8em;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                      {{selected_doctor.doctor_id}}
                      </p>
                    </div>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-row>
            <v-row class="AllAvailableClass" v-if="allWalkinsList.length > 0">
              <p class="AllAvailableTextClass">Selected Doctors1 ( {{allWalkinsList.length}} )</p>
            </v-row>
            <v-row class="AllAvailableClass" v-if="all_doctor_list.length > 0">
              <p class="AllAvailableTextClass">Available doctors ( {{all_doctor_list.length}} )</p>
            </v-row>
            <v-row style="padding-left: 36px; padding-top: 30px; height: 30vh; overflow-y: scroll;">
              <div style="display: flex; flex-flow: wrap; flex-direction:row;">
                <div align="center" v-for="doctor in all_doctor_list" style="margin: 0 8px 24px 0; ">
                  <v-card
                    elevation="2"
                    height="160"
                    width="158"
                    justify="center"
                    @click="AddParticularDoctor(doctor)"
                    style="margin-right:8px;"
                    >
                    <v-avatar style="padding-top: 10%" size="70">
                      <img
                        v-bind:src="doctor.doctor_profile_picture"
                        alt="Image"
                      />
                    </v-avatar>
                    <div>
                      <h3 style="padding-top: 6%; font-size: 0.9em">
                        {{ doctor.doctor_name }}
                      </h3>
                    </div>
                    <div style="justify-content: center; padding-top: 3%">
                      <p
                        style="
                          font-weight: 300;
                          padding: 0px 5px;
                          font-size: 0.8em;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                      {{doctor.doctor_id}}
                      </p>
                    </div>
                  </v-card>
                </div>
              </div>
            </v-row>
            <v-row>
              <div style="width: 100%" align="right">
                <v-btn style="padding: 20px; margin: 10px 30px 30px 10px;" class="primary" @click="ModifyWalkin()" :disabled = "(selected_doctor_list.length == 0)">
                  <span>Update</span>
                  <span v-if="isDisabled">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </span>
                </v-btn> -->
                <!-- <v-btn style="padding: 20px; margin: 10px 30px 30px 10px;" class="primary" @click="CloseEditWalkIn()" :disabled = "isDisabled">
                  <span>Cancel</span>
                </v-btn>
              </div>
            </v-row>
          </div>
        </v-card>
      </v-dialog> -->
      <!-- Edit popp Done -->
      <v-row style="height: 96vh; background-color: #f5faff;">
        <v-col cols="2">
          <SideBar :pageName="pageName"></SideBar>
        </v-col>
        <v-col cols="9">
           <div style="margin: 2px 20px 20px 50px ">
          <div v-if="!isLoading" align="left" style="display: flex;flex-direction: row ;justify-content: space-between;">
            <div style="width:30%" >
              <span style="font-weight: 800;padding-left: 5px; font-size: 12pt; text-transform: uppercase; letter-spacing: 1px;">
                <h3 style="margin-top: 3%;">Doctor Walk-in</h3>
              </span>
            </div>
            <div style="display: flex; justify-content: space-around; width: 25%; margin:18px 10px; height:39px">
              <div style="width: 20%">
                <div style="border-style: solid; border-color: white; border-radius: 8px; border-width: 0.1em;">
                  <span style="font-size: 26pt; display: flex; justify-content: space-between;align-items: end;">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }" style="display: flex; justify-content: end; align-items: center;">
                        <v-btn :disabled="isAddDisabled" append rounded color="primary" v-on="on" style="border-radius: 8px; height: 35px; margin-top:2vh;background-color: #1467BF;" @click="addWalkin">
                          <span style="font-size: 18pt;"> + </span>
                          <span style="font-weight: 600;margin-top:2%"> Add Walk-in Doctors</span>
                          <span v-if="isAddDisabled">
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </span>
                        </v-btn>
                      </template>
                      <span>Add Walk-in date</span>
                    </v-tooltip>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div align="center" style="padding-left: 64px; padding-top: 20px;" v-if="isLoading">
            <div>
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </div>
          </div>
          <div style="padding-left: 0px; padding-top: 48px;" v-else-if="allWalkinsList.length > 0">
            <div class="fixTableHead">
              <table class="tableClass" style="width: 100%;" v-if="allWalkinsList.length > 0">
                <thead>
                  <tr>
                    <th>Doctors</th>
                    <!-- <th>Specializations</th> -->
                    <th>Specialization</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="walkinrecord in allWalkinsList">
                    <td>{{walkinrecord.doctor_name}}</td>
                    <!-- <td>{{walkinrecord.specializations}}</td> -->
                    <td>{{walkinrecord.specialist[0].name ? walkinrecord.specialist[0].name : walkinrecord.specialist[0].specialization_name}}</td>
                    <td>
                      <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a style="width: 100%; margin:5px;" icon @click="viewDoctors(walkinrecord)" depressed v-on="on">
                            <img src="https://s3iconimages.mymedicine.com.mm/walkin_view.svg" style="height: 24px; width: 24px;"/>
                          </a>
                        </template>
                        <span>View</span>
                      </v-tooltip> -->
                      <!-- <v-tooltip bottom v-if="walkinrecord.allowedEdit">
                        <template v-slot:activator="{ on }">
                          <a style="width: 100%; margin: 5px;" icon @click="editDate(walkinrecord)" depressed v-on="on">
                            <img src="https://s3iconimages.mymedicine.com.mm/walkin_edit.svg" style="height: 24px; width: 24px;"/>
                          </a>
                        </template>
                        <span>Edit</span>
                      </v-tooltip> -->
                      <v-tooltip bottom v-if="walkinrecord.allowedDelete">
                        <template v-slot:activator="{ on }">
                          <a style="width: 100%; margin: 5px;" icon @click="deleteDoctor(walkinrecord)" depressed v-on="on" >
                            <img src="https://s3iconimages.mymedicine.com.mm/delete.svg" style="height: 24px; width: 24px;"/>
                          </a>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="pagination_list > 1" style="padding-top:20px;">
                <v-pagination circle v-model="page" :length="pagination_list" :total-visible="7" @input="findPage(page)" ></v-pagination>
              </div>
            </div>
          </div>
          <div v-else>
            No Walk-ins generated
          </div>
        </div></v-col>
      </v-row>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import { axios_auth_instance_admin } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';

export default {
  name: "ViewAllSuggestion",
  components: {
    NavBar,SideBar,
  },
  data() {
    return {
      pageName: "",
      currentUser: "",
      currentUserName: "",
      currentUserId: "",
      isLoading: false,
      allWalkinsList: [],
      createPopup: false,
      editPopup: false,
      start_gen_date: [],
      menu: null,
      specialization_list: [],
      selected_specialization_list: [],
      all_doctor_list: [],
      selected_doctor_list: [],
      popupIsLoading: false,
      isAddDisabled: false,
      old_dates: [],
      isDisabled: false,
      viewPopup: false,
      readDate: "",
      isViewLoading: false,
      isEditLoading: false,
      modifyId: "",
      pagination_list:"",
      page:'',
    };
  },
  mounted() {
    this.pageName = "AdminWalkIn";
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      this.isLoading = true;
      var get_admin_body = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        adminType: "ADMIN"
      };
      axios.post(process.env.VUE_APP_BACKEND_URL + "/getAdmin",get_admin_body)
      .then((adminReadSuccess) =>{
        this.currentUserName = adminReadSuccess.data.data.admin_name;
        this.currentUserId = adminReadSuccess.data.data._id;
        var data = {
          token : this.currentUser,
          typeOfUser : "ADMIN",
          page: 1,
          limit: 5
        };
        //paginatedWalkInRecords
        axios_auth_instance_admin.post("/getWalkInDoctorsV2", data)
        .then((successResponse) => {
          this.pagination_list = successResponse.data.pages;
          this.page = 1;
          this.selected_doctor_list = successResponse.data.data;
          this.allWalkinsList = successResponse.data.data;

          this.allWalkinsList.forEach((x)=>{
          x.allowedDelete = true;
          x.disabledDelete = false;
          x.Loading = false;
          //this.allWalkinsList.push(x);
          });
          this.isLoading = false;
        })
        .catch((errorResponse) =>{
          this.isLoading = false;
          handleError(errorResponse, this.$router, 'getWalkInDoctorsV2', 'AdminLogin', 'token');
        });
      })
      .catch((adminReadError) =>{
        console.log(adminReadError);
        this.isLoading = false;
      });
    }
  },
  methods: {
	  findPage(pageNum) {
		  this.isLoading = true
		  var data = {
			  token : this.currentUser,
			  typeOfUser : "ADMIN",
			  page : pageNum,
			  limit : 5
			  };
			  axios_auth_instance_admin.post("/getWalkInDoctorsV2", data)
			  .then((successResponse) => {
				  // console.log("Response",successResponse.data.data )
          this.pagination_list = successResponse.data.pages;
          this.page = pageNum;
          this.allWalkinsList = successResponse.data.data;
          this.allWalkinsList.forEach((x)=>{
            x.allowedEdit = true;
            x.allowedDelete = true;
            // this.allWalkinsList.push(x);
          });
          this.isLoading = false;
        })
        .catch((errorResponse) =>{
          this.isLoading = false;
          handleError(errorResponse, this.$router, 'getWalkInDoctorsV2', 'AdminLogin', 'token');
        });
      },
    ModifyWalkin()
    {
      //var doc_list = this.selected_doctor_list.map((x) => { return {'_id': x._id, 'doctor_name': x.doctor_name, 'doctor_profile_picture': x.doctor_profile_picture, 'doctor_id': x.doctor_id, 'service_fee': x.service_fee, 'commission': x.commission}});
      var doc_list = this.selected_doctor_list.map((x) => { return {'_id': x._id}});
      var modify_body ={
        'doctorsList': doc_list,
        'token': this.currentUser,
        'typeOfUser': "ADMIN"
      }
      this.isDisabled = true;
      axios.post(process.env.VUE_APP_BACKEND_URL + "/walkInDoctors_v2", modify_body)
      .then((modifySuccess) =>{
        window.alert("Modified");
        this.$router.go();
      })
      .catch((modifyFailure) =>{
        console.log(modifyFailure);
        window.alert(modifyFailure);
      });
    },
    queryEditDoctors()
    {
      this.popupIsLoading = true;
      // this.selected_doctor_list = [];
      this.all_doctor_list = [];
      //For multiple inputs
      // var spec_list = this.selected_specialization_list.map((x) => {return x._id;});

      //for Single input
      var spec_list = [this.selected_specialization_list._id];

      var doctorData = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        specializationsList: spec_list,
      };
      axios
      .post(
        process.env.VUE_APP_BACKEND_URL + "/getDoctorsBySpecializationWalkIn",
        doctorData
      )
      .then((specialistDataListResponse) => {
        var doctor_list = specialistDataListResponse.data.data;
        doctor_list.forEach((x) => {
          if (!x.doctor_profile_picture || x.doctor_profile_picture == "") {
            if (x.gender == "Female") {
              x.doctor_profile_picture =
                "https://s3iconimages.mymedicine.com.mm/female.svg";
            } else {
              x.doctor_profile_picture =
                "https://s3iconimages.mymedicine.com.mm/male.svg";
            }
          }
        });
        this.all_doctor_list = doctor_list.filter(({ _id: id1 }) => !this.selected_doctor_list.some(({ _id: id2 }) => id2 === id1));
        this.popupIsLoading = false;
      })
      .catch((specialistDataListError) =>{
        console.log(specialistDataListError);
        this.popupIsLoading = false;
      });
    },
    viewDoctors(dateObject)
    {
      this.isViewLoading = true;
      this.selected_doctor_list = [];
      this.readDate = dateObject.date_of_walkIn;
      var read_body = {
        dateOfWalkIn: this.readDate,
        token: this.currentUser,
        typeOfUser: "ADMIN"
      };
      axios.post(process.env.VUE_APP_BACKEND_URL + "/readWalkInByDate",read_body)
      .then((readSuccess) =>{
        this.selected_doctor_list = readSuccess.data.walkIn_data.doctors_list;
        this.viewPopup = true;
        this.isViewLoading = false;
      })
      .catch((readError) =>{
        console.log(readError);
        window.alert(readError);
        this.isViewLoading = false;
      });
    },
    editDate(dateObject)
    {
      this.editPopup = true;
    },
    deleteDoctor(doctorObject)
    {
      var delete_body = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        walkInId: doctorObject._id
      };
      axios_auth_instance_admin.post("/deleteWalkIn_v2", delete_body)
      .then((deleteResponse) => {
        window.alert("Deleted");
        this.$router.go();
      })
      .catch((deleteError) =>{
        handleError(deleteError, this.$router, 'deleteError', 'AdminLogin', 'token');
      });
    },
    CloseCreateWalkIn()
    {
      this.selected_doctor_list = [];
      this.selected_specialization_list = [];
      this.all_doctor_list = [];
      this.start_gen_date = [];
      this.createPopup = false;
    },
    CloseViewWalkin()
    {
      this.viewPopup = false;
    },
    CloseEditWalkIn()
    {
      this.selected_doctor_list = [];
      this.selected_specialization_list = [];
      this.all_doctor_list = [];
      this.start_gen_date = [];
      this.editPopup = false;
    },
    RemoveParticular(doctor)
    {
      this.all_doctor_list.unshift(doctor);
      this.selected_doctor_list = this.selected_doctor_list.filter((item) => item !== doctor);
    },
    AddParticularDoctor(doctor)
    {
      this.selected_doctor_list.unshift(doctor);
      this.all_doctor_list = this.all_doctor_list.filter((item) => item !== doctor);
    },
    queryDoctors()
    {
      this.popupIsLoading = true;
      // this.selected_doctor_list = [];
      // this.all_doctor_list = [];
      //For multiple inputs
      // var spec_list = this.selected_specialization_list.map((x) => {return x._id;});

      //for Single input
      var spec_list = [this.selected_specialization_list._id];

      var doctorData = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        specializationsList: spec_list,
      };
      axios
      .post(
        process.env.VUE_APP_BACKEND_URL + "/getDoctorsBySpecializationWalkIn",
        doctorData
      )
      .then((specialistDataListResponse) => {
        var doctor_list = specialistDataListResponse.data.data;
        doctor_list.forEach((x) => {
          if (!x.doctor_profile_picture || x.doctor_profile_picture == "") {
            if (x.gender == "Female") {
              x.doctor_profile_picture =
                "https://s3iconimages.mymedicine.com.mm/female.svg";
            } else {
              x.doctor_profile_picture =
                "https://s3iconimages.mymedicine.com.mm/male.svg";
            }
          }
        });
        // this.all_doctor_list = doctor_list;
        this.all_doctor_list = doctor_list.filter(({ _id: id1 }) => !this.selected_doctor_list.some(({ _id: id2 }) => id2 === id1));
        this.popupIsLoading = false;
      })
      .catch((specialistDataListError) =>{
        console.log(specialistDataListError);
        this.popupIsLoading = false;
      });
    },
    allowedDates(val)
    {
      return !this.old_dates.includes(val)
    },
    addWalkin()
    {
      this.isAddDisabled = true;
      this.selected_doctor_list = [];
      this.selected_specialization_list = [];
      this.all_doctor_list = [];
      var spec_body = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/readAllSpecializations", spec_body)
      .then((readSpecSuccess) =>{
        var specialization_list = readSpecSuccess.data.data;
        this.specialization_list = specialization_list;
        var old_dates_body = {
          token : this.currentUser,
          typeOfUser : "ADMIN",
          page: 1,
          limit: 1000
        };
        axios_auth_instance_admin.post("/getWalkInDoctorsV2", old_dates_body)
        .then((readDatesSuccess) => {
          this.selected_doctor_list = readDatesSuccess.data.data;
          this.createPopup = true;
          this.isAddDisabled = false;
        })
        .catch((readDatesError) =>{
          handleError(readDatesError, this.$router, 'getWalkInDoctorsV2', 'AdminLogin', 'token');
        })
      })
      .catch((readSpecError) => {
        window.alert(readSpecError);
        console.log(readSpecError);
        this.isAddDisabled = false;
      });
    },
  },
};
</script>

<style scoped>
.ViewTitle {
  background-color: #1467BF;
  display: flex;
  justify-content: space-between;
}
.StaticDateClass {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: #333333;
  padding: 36px;
}
.AllAvailableTextClass {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-transform: capitalize;
  color: #1467BF;
}
.AllAvailableClass{
  padding-left: 36px;
  padding-top: 15px;
  background-color: #D4EFFF;
}
.TitleHeader {
  display: flex;
  justify-content: space-between;
  background-color: #1467BF;
}
.TitleText {
  padding: 30px 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #FFFFFF;
}
.fixTableHead {
  overflow-y: scroll;
  height: 50vh;
}
/* .fixTableHead thead th {
  position: sticky;
  top: 0;
} */
table {
  border-collapse: collapse;
  width: 100%;
}
::-webkit-scrollbar {
  width: 10px !important;
}
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}
::-webkit-scrollbar-thumb {
  background: grey !important;
  width: 10px;
}
td,
th {
  border: 0px;
}
td {
  padding: 2px;
  font-size: 10pt;
  font-weight: 700;
}
th {
  background-color: #1467bf;
  color: white;
  border: 0px;
  font-size: 8pt;
  font-weight: 500;
  margin-left: 10%;
  position: sticky;
  top: 0;
  z-index: 0;
  height: 40px;
}
tr {
  border-bottom: #d4eeff 1px solid !important;
}
.tableClass {
  border-spacing: 0px;
  width: 100%;
  border-collapse: collapse;
  z-index: 0;
  background-color: white;
  margin-top:10px;
}
table > tr > td:last-child {
    white-space: nowrap;
}
.activeClass {
  background-color: #1467BF !important;
  color: white !important;
  padding: 0px !important;
  margin: 0px !important;
  box-shadow: 0px 0px 28px 0px #1366be4d !important;
}
</style>